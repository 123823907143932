import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Route, Routes, useSearchParams } from "react-router-dom";
import "./App.css";
import ChatList from "./components/chat/ChatList";
import ChatMessages from "./components/chat/ChatMessages";

import Greeting from "./components/Greeting";
import { config } from "./constants/config";
import {
  InitiateRequest,
  UserAction,
  UserStreamRequest,
  UserStreamResponse,
} from "./proto/chat_pb";
import { ChatServiceClient } from "./proto/ChatServiceClientPb";
import { createNewChat, endChatSession } from "./services/chat.service";
import {
  addToChatList,
  changeStatus,
  IRootState,
  setChatList,
  setCode,
  setUser,
  updateChat,
} from "./store";
import { ClientReadableStream, RpcError, StatusCode } from "grpc-web";
import { status as RpcWebStatus } from "@grpc/grpc-js";
import { Status } from "@grpc/grpc-js/build/src/constants";

export const client = new ChatServiceClient(config.grpcUrl);

function App() {
  const { code, user } = useSelector((state: IRootState) => state.app);
  const [userStream, setUserStream] =
    useState<ClientReadableStream<UserStreamResponse> | null>(null);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigator = useNavigate();

  const navigateToChat = async () => {
    const userId = searchParams.get("userId");
    const withUserId = searchParams.get("withUserId");

    if (!userId || !withUserId) {
      return;
    }

    const { code } = await createNewChat(userId, withUserId);

    await handleUserNameSubmit(code);
    navigator(`/${withUserId}`);
  };

  useEffect(() => {
    navigateToChat();

    return () => {
      console.log("refresh");
      endChatSession(code);

      userStream?.cancel();
    };
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    connectToUserStream();
  }, [user]);

  const handleCallback = (event: string, data: any) => {
    console.log(event, data);
  };

  const getUserStreamData = (chunk: UserStreamResponse) => {
    const { usersList, action } = chunk.toObject();
    const [updatedChat] = usersList;

    switch (action) {
      case UserAction.GET:
        return dispatch(setChatList(usersList));
      case UserAction.NEW:
        return dispatch(addToChatList(usersList));
      case UserAction.CHANGE_STATUS:
        return dispatch(changeStatus(updatedChat));
      case UserAction.UPDATE:
        dispatch(
          updateChat({
            id: updatedChat.id,
            lastMessage: updatedChat.lastMessage,
            lastMessageDate: updatedChat.lastMessageDate,
            unreadCount: 1,
          })
        );
    }
  };

  const handleError = (err: RpcError) => {
    console.log(StatusCode);

    switch (err.code as unknown as RpcWebStatus) {
      case Status.UNKNOWN:
      case Status.UNAVAILABLE:
        userStream?.cancel();
        setUserStream(null);

        setTimeout(() => {
          console.log("reconnect attempt");

          connectToUserStream();
        }, 1000);
        break;
      default:
        console.log("error", err);
    }
  };

  const connectToUserStream = () => {
    const req = new UserStreamRequest();

    req.setCode(code);

    const userStream = client.userStream(req, {});

    setUserStream(userStream);

    userStream.on("data", getUserStreamData);
    userStream.on("status", (status) => handleCallback("status", status));
    userStream.on("end", () => handleCallback("end", "end"));
    userStream.on("error", handleError);
    userStream.on("metadata", (mtd) => handleCallback("metadata", mtd));
  };

  const handleUserNameSubmit = async (code: string) => {
    if (!code) {
      return;
    }

    dispatch(setCode(code));
    const req = new InitiateRequest();
    req.setCode(code);

    await client.chatInitiate(req, {}, (err, resp) => {
      if (err) {
        return console.log(err);
      }
      const { id, name, avatarUrl, status } = resp.toObject();

      dispatch(
        setUser({
          id,
          name,
          avatarUrl,
          status,
        })
      );
    });
  };

  return (
    <div className="App">
      <div className="App-container">
        {!user ? (
          <Greeting onCodeEnter={handleUserNameSubmit} />
        ) : (
          <Routes>
            <Route path="/" element={<ChatList />} />
            <Route path=":id" element={<ChatMessages />} />
          </Routes>
        )}
      </div>
    </div>
  );
}

export default App;
