import dayjs from 'dayjs';
import { StreamMessage, User } from '../../../proto/chat_pb';
import { BUTTONS_ANIMATION_TIME } from '../constants';


export const parseDecimalNumbers = (str: string) => parseInt(str, 10);

export const afterButtonsAnimation = (element: HTMLElement | null, classname: string, styleLeft: number) => {
    setTimeout(() => {
        if (!element) {
            return;
        }

        element.classList.remove(classname);
        element.style.left = `${styleLeft}px`;
    }, BUTTONS_ANIMATION_TIME);
};

export const formatChatDate = (date: number) => dayjs(date).format('hh:mm a');

export const sortChatsAsc = (chats: User.AsObject[]) => chats.sort((a, b) => b.lastMessageDate - a.lastMessageDate);
export const sortMessagesDesc = (chats: StreamMessage.AsObject[]) => chats.sort((a, b) => a.date - b.date);
export const sortMessagesAsc = (chats: StreamMessage.AsObject[]) => chats.sort((a, b) => b.date - a.date);
