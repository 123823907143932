import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useSwipeable } from 'react-swipeable';
import { User } from '../../proto/chat_pb';
import classes from './chat-item.module.css';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BlockIcon from '@material-ui/icons/Block';
import { BUTTONS_ANIMATION_STYLE_LEFT, BUTTONS_INITIAL_STYLE_LEFT } from './constants';
import { afterButtonsAnimation, formatChatDate, parseDecimalNumbers } from './helpers';

interface Props {
    item: User.AsObject;
}

const ChatItem: React.FC<Props> = ({ item }) => {
    const navigator = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            if (!ref.current || parseDecimalNumbers(ref.current.style.left)) {
                return;
            }

            ref.current.classList.add(classes.showButtons);

            afterButtonsAnimation(
                ref.current,
                classes.showButtons,
                BUTTONS_ANIMATION_STYLE_LEFT
            );
        },
        onSwipedRight: () => {
            if (!ref.current || !parseDecimalNumbers(ref.current.style.left)) {
                return;
            }

            ref.current.classList.add(classes.hideButtons);

            afterButtonsAnimation(
                ref.current,
                classes.hideButtons,
                BUTTONS_INITIAL_STYLE_LEFT
            );
        },
        preventDefaultTouchmoveEvent: true,
        delta: 10,
    })

    useEffect(() => {
        swipeHandlers.ref(ref.current);
    }, [ref, swipeHandlers]);

    const handleChatClick = () => {
        navigator(`/${item.id}`);
    };

    return (
        <div
            {...swipeHandlers}
            ref={ref}
            className={classes.itemTab}
        >
            <div
                className={classes.chatItem}
                onClick={handleChatClick}
            >
                <div className={classes.avatarContainer}>
                    <img className={classes.avatar} src={item.avatarUrl} alt={item.name} />
                    { item.status ? <div className={classes.online}></div> : null }
                </div>
                <div className={classes.container}>
                    <div className={classes.chatItemTitleContainer}>
                        <div className={classes.name}>
                            {item.name}
                        </div>
                        <div className={classes.date}>
                            {formatChatDate(item.lastMessageDate)}
                        </div>
                    </div>
                    <div className={classes.chatItemInfoContainer}>
                        <div className={classes.text}>
                            {item.lastMessage}
                        </div>
                        {
                            item.unreadCount ? (
                                <div className={classes.count}>
                                    {item.unreadCount}
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
            <div className={classes.buttons}>
                <div className={classNames(classes.button, classes.block)}>
                    <div>
                        <BlockIcon />
                        <span>Block</span>
                    </div>
                </div>
                <div className={classNames(classes.button, classes.mute)}>
                    <div>
                        <VolumeOffIcon />
                        <span>Mute</span>
                    </div>
                </div>
                <div className={classNames(classes.button, classes.delete)}>
                    <div>
                        <DeleteForeverIcon />
                        <span>Delete</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatItem;
