import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Typopgraphy from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const style: { [key: string]: React.CSSProperties } = {
  paper: {
    height: "30vh",
    width: "30%",
    backgroundColor: "lightslategrey",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  input: {
    marginTop: "50px",
    width: "50%",
    color: "white",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    height: 80,
    width: 80,
    margin: "2rem 0rem",
  },
};

interface Props {
  onCodeEnter: (code: string) => void;
}

const Greeting: React.FC<Props> = (props) => {
  const [code, setCode] = useState("");
  const { onCodeEnter } = props;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onCodeEnter(code);
  };

  return (
    <>
      <div style={style.paper}>
        <form onSubmit={handleSubmit} style={style.form}>
          <Typopgraphy variant="h5">Please enter your code</Typopgraphy>
          <TextField
            style={style.input}
            placeholder="Enter code for chat..."
            value={code}
            onChange={(e) => setCode(e.target.value)}
            InputLabelProps={{
              style: {
                color: "white",
              },
            }}
            InputProps={{
              style: {
                color: "white",
              },
            }}
          />
        </form>
      </div>
    </>
  );
};

export default Greeting;
