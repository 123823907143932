import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../store';
import ChatItem from './ChatItem';
import { sortChatsAsc } from './helpers';

const ChatList: React.FC = () => {
    const { chatList } = useSelector((state: IRootState) => state.chat);

    return (
        <>
            {sortChatsAsc([...chatList]).map(item => <ChatItem key={item.id} item={item} />)}
        </>
    );
};

export default ChatList;
