import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { appReducer } from './app.slice';
import { chatReducer } from './chat.slice';
import { messageReducer } from './message.slice';


const rootReducer = combineReducers({
  app: appReducer,
  chat: chatReducer,
  message: messageReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type IRootState = ReturnType<typeof rootReducer>;
