import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../proto/chat_pb';
import { IChatState } from './types';

const initialState: IChatState = {
  chatList: [],
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatList: (state: IChatState, { payload }: PayloadAction<User.AsObject[]>) => ({
      ...state,
      chatList: payload,
    }),
    addToChatList: (state: IChatState, { payload }: PayloadAction<User.AsObject[]>) => ({
      ...state,
      chatList: [...state.chatList, ...payload],
    }),
    updateChat: (state: IChatState, { payload }: PayloadAction<Partial<User.AsObject>>) => {
      const { chatList, findUser} = findAndUpdateChat(state, payload.id);

      chatList.push({
        ...findUser,
        lastMessage: payload.lastMessage!,
        lastMessageDate: payload.lastMessageDate!,
        unreadCount: !payload.unreadCount ? 0 : findUser!.unreadCount + 1,
      });

      return {
        ...state,
        chatList: [...chatList],
      }
    },
    changeStatus: (state: IChatState, { payload }: PayloadAction<Partial<User.AsObject>>) => {
      const { chatList, findUser} = findAndUpdateChat(state, payload.id);

      chatList.push({
        ...findUser,
        status: payload.status!,
      });

      return {
        ...state,
        chatList: [...chatList],
      }
    }
  },
});

function findAndUpdateChat (state: IChatState, chatId?: number) {
  const result = [...state.chatList];
  let index = -1;
  const findUser = result.find((usr, i) => {
    if (usr.id === chatId) {
      index = i;

      return true;
    }

    return false;
  });

  result.splice(index, 1);

  return {
    chatList: result,
    findUser: findUser!,
  };
}


export const { reducer: chatReducer } = chatSlice;
export const { setChatList, addToChatList, updateChat, changeStatus } = chatSlice.actions;
