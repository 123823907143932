import axios from "axios";
import { config } from "constants/config";

const tempCode = "12345";

interface IDbMessage {
  text: string;
  authorId: number;
  createdAt: Date;
  id: string;
}

export const createNewChat = async (userId: string, withUserId: string) => {
  const { data } = await axios.get<{ code: string }>(
    `${config.apiUrl}/chat/initChat/${userId}/${withUserId}?code=${tempCode}`
  );

  return data;
};

export const endChatSession = async (code: string) => {
  return axios.get(`${config.apiUrl}/chat/end-session?code=${code}`);
};

export const getChatMessages = async (code: string, withUserId: number) => {
  return axios.get<IDbMessage[]>(
    `${config.apiUrl}/chat/messages/${withUserId}?code=${code}`
  );
};
