import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { LongPressDetectEvents, useLongPress } from 'use-long-press';
import { InitiateResponse, StreamMessage } from '../../proto/chat_pb';
import { IRootState, Nullable } from '../../store';
import classes from './chat-message.module.css';
import { DeleteContextButton } from './DeleteContextButton';
import { formatChatDate } from './helpers';


interface Props {
    message: StreamMessage.AsObject;
    companion: Nullable<InitiateResponse.AsObject>;
}

export const ChatMessage: React.FC<Props> = ({
    message,
    companion,
}) => {
    const { app } = useSelector((state: IRootState) => state);
    const [currentUser, setCurrentUser] = useState<Nullable<InitiateResponse.AsObject>>(app.user);
    const ref = useRef<HTMLDivElement>(null);
    const [contextMenuRect, setContextMenuRect] = useState<Nullable<DOMRect>>(null);
    const handleDelete = useCallback(() => {
        if (!ref.current) {
            return;
        }

        const rect = ref.current.getBoundingClientRect();

        setContextMenuRect(rect);
    }, []);
    const bind = useLongPress(handleDelete, {
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: true,
        detect: LongPressDetectEvents.BOTH,
    });

    useEffect(() => {
        const current = isMyMessage(message.userId) ? companion : app.user;

        setCurrentUser(current);
    }, [app.user, companion]);

    const isMyMessage = (messageUserId: number) => messageUserId !== app.user?.id;

    return currentUser ? (
        <div className={classNames(
            classes.messageContainer,
            {[classes.myMessageContainer]: isMyMessage(message.userId)}
        )}>
            <div className={classes.time}>
                <span>{formatChatDate(message.date)}</span>
            </div>
            <div className={classNames(
                classes.message,
                {[classes.myMessage]: isMyMessage(message.userId)}
            )}>
                <div className={classes.messageBlock} {...bind} ref={ref}>
                    <div
                        className={classNames(
                            classes.messageUserInfo,
                            {[classes.myMessageUserInfo]: isMyMessage(message.userId)}
                        )}>
                        <span>{currentUser.name}</span>
                        <img className={classes.image} src={currentUser.avatarUrl} alt={currentUser.name} />
                    </div>
                    <div className={classNames(
                        classes.messageText,
                        {[classes.myMessageText]: isMyMessage(message.userId)}
                    )}>{message.message}</div>
                </div>
            </div>
            {
                contextMenuRect ?
                    (<DeleteContextButton
                        isMyMessage={isMyMessage(message.userId)}
                        setContextMenuRect={setContextMenuRect}
                        contextMenuRect={contextMenuRect}
                        message={message}
                    />) :
                    null
            }
        </div>
    ) : null;
}
