/**
 * @fileoverview gRPC-Web generated client stub for chatPackage
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as chat_pb from './chat_pb';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';


export class ChatServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorChatInitiate = new grpcWeb.MethodDescriptor(
    '/chatPackage.ChatService/ChatInitiate',
    grpcWeb.MethodType.UNARY,
    chat_pb.InitiateRequest,
    chat_pb.InitiateResponse,
    (request: chat_pb.InitiateRequest) => {
      return request.serializeBinary();
    },
    chat_pb.InitiateResponse.deserializeBinary
  );

  chatInitiate(
    request: chat_pb.InitiateRequest,
    metadata: grpcWeb.Metadata | null): Promise<chat_pb.InitiateResponse>;

  chatInitiate(
    request: chat_pb.InitiateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: chat_pb.InitiateResponse) => void): grpcWeb.ClientReadableStream<chat_pb.InitiateResponse>;

  chatInitiate(
    request: chat_pb.InitiateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: chat_pb.InitiateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/chatPackage.ChatService/ChatInitiate',
        request,
        metadata || {},
        this.methodDescriptorChatInitiate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/chatPackage.ChatService/ChatInitiate',
    request,
    metadata || {},
    this.methodDescriptorChatInitiate);
  }

  methodDescriptorSendMessage = new grpcWeb.MethodDescriptor(
    '/chatPackage.ChatService/SendMessage',
    grpcWeb.MethodType.UNARY,
    chat_pb.MessageRequest,
    google_protobuf_empty_pb.Empty,
    (request: chat_pb.MessageRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  sendMessage(
    request: chat_pb.MessageRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  sendMessage(
    request: chat_pb.MessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  sendMessage(
    request: chat_pb.MessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/chatPackage.ChatService/SendMessage',
        request,
        metadata || {},
        this.methodDescriptorSendMessage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/chatPackage.ChatService/SendMessage',
    request,
    metadata || {},
    this.methodDescriptorSendMessage);
  }

  methodDescriptorDeleteMessages = new grpcWeb.MethodDescriptor(
    '/chatPackage.ChatService/DeleteMessages',
    grpcWeb.MethodType.UNARY,
    chat_pb.DeleteMessagesRequest,
    google_protobuf_empty_pb.Empty,
    (request: chat_pb.DeleteMessagesRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteMessages(
    request: chat_pb.DeleteMessagesRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  deleteMessages(
    request: chat_pb.DeleteMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteMessages(
    request: chat_pb.DeleteMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/chatPackage.ChatService/DeleteMessages',
        request,
        metadata || {},
        this.methodDescriptorDeleteMessages,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/chatPackage.ChatService/DeleteMessages',
    request,
    metadata || {},
    this.methodDescriptorDeleteMessages);
  }

  methodDescriptorUserStream = new grpcWeb.MethodDescriptor(
    '/chatPackage.ChatService/UserStream',
    grpcWeb.MethodType.SERVER_STREAMING,
    chat_pb.UserStreamRequest,
    chat_pb.UserStreamResponse,
    (request: chat_pb.UserStreamRequest) => {
      return request.serializeBinary();
    },
    chat_pb.UserStreamResponse.deserializeBinary
  );

  userStream(
    request: chat_pb.UserStreamRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<chat_pb.UserStreamResponse> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/chatPackage.ChatService/UserStream',
      request,
      metadata || {},
      this.methodDescriptorUserStream);
  }

  methodDescriptorChatStream = new grpcWeb.MethodDescriptor(
    '/chatPackage.ChatService/ChatStream',
    grpcWeb.MethodType.SERVER_STREAMING,
    chat_pb.ChatStreamRequest,
    chat_pb.StreamMessage,
    (request: chat_pb.ChatStreamRequest) => {
      return request.serializeBinary();
    },
    chat_pb.StreamMessage.deserializeBinary
  );

  chatStream(
    request: chat_pb.ChatStreamRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<chat_pb.StreamMessage> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/chatPackage.ChatService/ChatStream',
      request,
      metadata || {},
      this.methodDescriptorChatStream);
  }

}

