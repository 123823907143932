import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StreamMessage } from "../proto/chat_pb";
import { IMessageState } from "./types";

const initialState: IMessageState = {
  messages: [],
  currentMessage: "",
};

const messageSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMessages: (
      state: IMessageState,
      { payload }: PayloadAction<StreamMessage.AsObject[]>
    ) => ({
      ...state,
      messages: [...state.messages, ...payload],
    }),
    setCurrentMessage: (
      state: IMessageState,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      currentMessage: payload,
    }),
    deleteMessage: (
      state: IMessageState,
      { payload }: PayloadAction<string>
    ) => {
      let index = -1;
      const currentMessages = [...state.messages];

      currentMessages.find((msg, i) => {
        if (msg.id === payload) {
          index = i;

          return true;
        }

        return false;
      });

      currentMessages.splice(index, 1);

      return {
        ...state,
        messages: [...currentMessages],
      };
    },
    clearMessage: () => initialState,
  },
});
export const { reducer: messageReducer } = messageSlice;
export const { setMessages, setCurrentMessage, clearMessage, deleteMessage } =
  messageSlice.actions;
