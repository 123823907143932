import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitiateResponse } from '../proto/chat_pb';
import { IAppState } from './types';

const initialState: IAppState = {
    code: '',
    user: null
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setCode: (state: IAppState, { payload }: PayloadAction<string>) => ({
            ...state,
            code: payload,
        }),
        setUser: (state: IAppState, { payload }: PayloadAction<InitiateResponse.AsObject>) => ({
            ...state,
            user: payload,
        }),
    },
});
export const { reducer: appReducer } = appSlice;
export const { setCode, setUser } = appSlice.actions;
