import classNames from "classnames";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { client } from "App";
import {
  ChatStreamRequest,
  MessageRequest,
  StreamMessage,
} from "proto/chat_pb";
import {
  clearMessage,
  IRootState,
  Nullable,
  setCurrentMessage,
  setMessages,
  updateChat,
} from "store";
import { ChatMessage } from "./ChatMessage";
import classes from "./chat-message.module.css";
import SendIcon from "@material-ui/icons/Send";
import { parseDecimalNumbers, sortMessagesDesc } from "./helpers";
import ArrowBackIosNewIcon from "@material-ui/icons/ArrowBackIos";
import { ClientReadableStream, RpcError, StatusCode } from "grpc-web";
import { getChatMessages } from "services/chat.service";
import { status as RpcWebStatus } from "@grpc/grpc-js";
import { Status } from "@grpc/grpc-js/build/src/constants";

const ChatMessages: React.FC = () => {
  const [chatStream, setChatStream] =
    useState<Nullable<ClientReadableStream<StreamMessage>>>(null);
  const [chatId, setChatId] = useState<Nullable<number>>(null);
  const { app, chat, message } = useSelector((state: IRootState) => state);
  const dispatch = useDispatch();
  const params = useParams();
  const navigator = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const connectToChatStream = (isReconnect = false) => {
    const req = new ChatStreamRequest();
    const chatId = parseDecimalNumbers(params.id!);

    req.setCode(app.code);
    req.setWithUserId(chatId);

    const chatStream = client.chatStream(req, {});

    if (isReconnect) {
      setChatStream(chatStream);
    }
  };

  const handleError = (err: RpcError) => {
    console.log(StatusCode);

    switch (err.code as unknown as RpcWebStatus) {
      case Status.UNKNOWN:
      case Status.UNAVAILABLE:
        chatStream?.cancel();
        setChatStream(null);

        setTimeout(() => {
          console.log("reconnect chat stream attempt");

          connectToChatStream(true);
        }, 1000);
        break;
      default:
        console.log("error", err);
    }
  };

  const scrollToBottom = () => {
    if (!ref.current) {
      return;
    }

    ref.current.scrollTop = ref.current.scrollHeight;
  };

  useEffect(() => {
    if (!chat.chatList.length) {
      return;
    }

    const req = new ChatStreamRequest();
    const chatId = parseDecimalNumbers(params.id!);

    req.setCode(app.code);
    req.setWithUserId(chatId);

    const chatStream = client.chatStream(req, {});

    getChatMessages(app.code, chatId).then((result) => {
      const messages = result.data.map((msg) => ({
        id: msg.id,
        userId: msg.authorId,
        toUserId: chatId,
        message: msg.text,
        date: new Date(msg.createdAt).getTime(),
      }));

      dispatch(setMessages(messages));

      setChatStream(chatStream);
      setChatId(chatId);

      scrollToBottom();
    });

    return () => {
      if (chatStream) {
        chatStream.cancel();
      }

      dispatch(clearMessage());
    };
  }, [params, app.code, chat.chatList.length]);

  useEffect(() => {
    if (chatStream && chatId) {
      chatStream.on("data", (chunk) => {
        const msg = chunk.toObject();

        console.log("message", msg);

        dispatch(setMessages([msg]));

        dispatch(
          updateChat({
            id: chatId,
            unreadCount: 0,
            lastMessage: msg.message,
            lastMessageDate: Date.now(),
          })
        );

        scrollToBottom();
      });

      dispatch(
        updateChat({
          id: chatId,
          unreadCount: 0,
          lastMessage: message.messages[message.messages.length - 1].message,
          lastMessageDate: Date.now(),
        })
      );

      chatStream.on("error", handleError);
    }
  }, [chatStream, chatId]);

  const handleMessageSubmit = (event: MouseEvent | FormEvent) => {
    event.preventDefault();

    if (!message.currentMessage.trim()) {
      return;
    }

    const msgRequest = new MessageRequest();

    msgRequest.setCode(app.code);
    msgRequest.setToUserId(parseDecimalNumbers(params.id!));
    msgRequest.setMessage(message.currentMessage);
    msgRequest.setIsFirst(!message.messages.length);

    client.sendMessage(msgRequest, null, (err, resp) => {
      if (err) {
        return console.log(err);
      }

      dispatch(setCurrentMessage(""));
    });
  };

  const handleGoBack = () => {
    navigator("/");
  };

  const getCompanion = (message: StreamMessage.AsObject) =>
    chat.chatList.find(({ id }) => id === message.userId) || app.user;

  return chat.chatList.length ? (
    <div className={classes.chat}>
      <div className={classes.goBack}>
        <ArrowBackIosNewIcon onClick={handleGoBack} fontSize="large" />
      </div>
      <div ref={ref} className={classes.chatBackground}>
        {sortMessagesDesc([...message.messages]).map((message, index) => (
          <ChatMessage
            key={`${message.date}-${index}`}
            companion={getCompanion(message)}
            message={message}
          />
        ))}
      </div>
      <div className={classes.inputContainer}>
        <img
          className={classNames(classes.image, classes.inputImage)}
          src={app.user!.avatarUrl}
          alt={app.user!.name}
        />
        <form
          onSubmit={handleMessageSubmit}
          id="form"
          className={classes.inputBox}
        >
          <input
            className={classes.input}
            type="text"
            value={message.currentMessage}
            onChange={(event) =>
              dispatch(setCurrentMessage(event.target.value))
            }
          />
        </form>
        <button
          type="submit"
          form="form"
          className={classes.button}
          onClick={handleMessageSubmit}
        >
          <SendIcon fontSize="small" />
        </button>
      </div>
    </div>
  ) : null;
};

export default ChatMessages;
