import { Button } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import React, { MouseEvent, TouchEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { client } from '../../App';
import { DeleteMessagesRequest, StreamMessage } from '../../proto/chat_pb';
import { deleteMessage, IRootState, Nullable, updateChat } from '../../store';
import classes from './contex-button.module.css';
import { parseDecimalNumbers, sortMessagesAsc } from './helpers';

interface IProps {
    isMyMessage: boolean;
    message: StreamMessage.AsObject;
    contextMenuRect: Nullable<DOMRect>;
    setContextMenuRect: (value: Nullable<DOMRect>) => void;
}

export const DeleteContextButton: React.FC<IProps> = ({
    isMyMessage,
    message,
    contextMenuRect,
    setContextMenuRect,
}) => {
    const { app, message: chatMessage } = useSelector((state: IRootState) => state);
    const dispatch = useDispatch();
    const params = useParams();
    const ref = useRef<HTMLButtonElement>(null);
    const onWindowClick = () => setContextMenuRect(null);

    useEffect(() => {
        if (!ref.current || !contextMenuRect) {
            return;
        }

        window.addEventListener('touchstart', onWindowClick);

        ref.current.style.left = isMyMessage ?
            `${contextMenuRect.left}px` :
            `${contextMenuRect.right - ref.current.offsetWidth}px`;
        ref.current.style.top = `${contextMenuRect.top + contextMenuRect.height + 10}px`;

        return () => window.removeEventListener('touchstart', onWindowClick);
    }, [ref.current]);

    const handleDelete = (event: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const chatId = parseDecimalNumbers(params.id!);
        const deleteRequest = new DeleteMessagesRequest();

        deleteRequest.setCode(app.code);
        deleteRequest.setToUserId(chatId);
        deleteRequest.setIdsList([message.id]);

        client.deleteMessages(deleteRequest, null,(err, resp) => {
            if (err) {
                return console.log();
            }

            const [ ,lastMessage ] = sortMessagesAsc([...chatMessage.messages]);

            dispatch(deleteMessage(message.id));
            dispatch(updateChat({
                id: chatId,
                unreadCount: 0,
                lastMessage: lastMessage.message,
                lastMessageDate: lastMessage.date
            }));
            setContextMenuRect(null);
        });
    };

    return (
        <button
            ref={ref}
            onClick={handleDelete}
            onTouchStart={e => e.stopPropagation()}
            onTouchEnd={handleDelete}
            className={classes.contextButton}
        >
            <span>Delete message</span>
            <DeleteForeverIcon className={classes.delete} />
        </button>
    );
}
